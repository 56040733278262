import React, { useState } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import hero1 from "../../assets/images/hero1.jpg";

export const Guide = () => {
  const [activeTab, setActiveTab] = useState("values");

  const renderContent = () => {
    switch (activeTab) {
      case "values":
        return (
          <Box>
            <Typography variant="h5" className="blutext" gutterBottom>
              Our Core Values
            </Typography>
            <ul style={{ paddingLeft: "1.5rem" }}>
              {[
                {
                  title: "Integrity & Honesty",
                  content:
                    "We uphold the highest standards of transparency in all dealings, building trust with clients, partners, and employees.",
                },
                {
                  title: "Customer Satisfaction",
                  content:
                    "We prioritize customer needs, ensuring outstanding service and lasting relationships.",
                },
                {
                  title: "Continuous Growth",
                  content:
                    "We encourage lifelong learning and skill development to stay ahead in the industry.",
                },
              ].map((item, index) => (
                <li key={index} style={{ marginBottom: "1rem" }}>
                  <Typography variant="h6" className="blutext">
                    {item.title}
                  </Typography>
                  <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
                    {item.content}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        );
      case "vision":
        return (
          <Box>
            <Typography variant="h5" color="primary" gutterBottom>
              Our Vision
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
              To be the most trusted partner in our industry, upholding integrity, exceeding customer expectations, and fostering innovation.
            </Typography>
          </Box>
        );
      case "mission":
        return (
          <Box>
            <Typography variant="h5" color="primary" gutterBottom>
              Our Mission
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
              Delivering exceptional service with integrity and innovation, ensuring reliable solutions tailored to customer needs.
            </Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <section className="about-section section-padding pt-0">
      <Box className="container">
        <Box maxWidth="sm" className="mx-auto mb-4 text-center">
          <Typography variant="h3" className="blutext" gutterBottom>
            How We Deliver Results
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
            Our approach is centered on understanding client needs, fostering innovation, and upholding excellence.
          </Typography>
        </Box>

        <Grid container spacing={6} alignItems="center">
          {/* Left Side - Content */}
          <Grid item xs={12} md={6}>
            {/* Tab Navigation */}
            <Box display="flex" gap={2} flexWrap="wrap" sx={{ justifyContent: { xs: "center", md: "start" } }}>
              {[
                { label: "Core Values", key: "values" },
                { label: "Vision", key: "vision" },
                { label: "Mission", key: "mission" },
              ].map((tab) => (
                <Button
                  key={tab.key}
                  onClick={() => setActiveTab(tab.key)}
                  variant={activeTab === tab.key ? "contained" : "outlined"}
                  sx={{
                    borderRadius: "6px",
                    textTransform: "none",
                    padding: "10px 20px",
                    fontWeight: "500",
                    backgroundColor: activeTab === tab.key ? "#010156" : "transparent",
                    color: activeTab === tab.key ? "#fff" : "#010156",
                    transition: "0.3s ease",
                    "&:hover": {
                      backgroundColor: "#010156",
                      color: "#fff",
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  {tab.label}
                </Button>
              ))}
            </Box>

            {/* Tab Content */}
            <Box mt={3} sx={{ padding: "20px", backgroundColor: "#f9f9f9", borderRadius: "8px" }}>
              {renderContent()}
            </Box>
          </Grid>

          {/* Right Side - Image */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "450px",
                borderRadius: "12px",
                overflow: "hidden",
                boxShadow: "0 10px 20px rgba(0,0,0,0.15)",
              }}
            >
              <img
                src={hero1}
                alt="Hero"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  transition: "0.3s ease-in-out",
                  filter: "brightness(0.9)",
                }}
              />
              {/* Gradient Overlay */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  background: "linear-gradient(to top, rgba(0,0,0,0.6), rgba(0,0,0,0))",
                  color: "#fff",
                  padding: "20px",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              >
                <Typography variant="h6">Empowering Innovation</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </section>
  );
};
