import React from "react";
import { Box } from "@mui/material";
import { EnterpriseBanner } from "../../components/Internet/enterprise/Banner";
import { EnterprisePlan } from "../../components/Internet/enterprise/EnterprisePlans";
import { AdRetail } from "../../components/Internet/enterprise/AdRetail";
import { OtherEnterprise } from "../../components/Internet/enterprise/OtherEnterprise";
import Footer from "../../components/Footer";



const Enterprise = () => {
    return(
        <Box>
            <EnterpriseBanner />
            <EnterprisePlan />
            <AdRetail />
            <OtherEnterprise />
            <Box>
                <Footer />
            </Box>
        </Box>
    )
}


export default Enterprise;