import React from "react";
import { AdSection } from "../AdSection";

export const AdRetail = () => {
    return (
        <AdSection 
            heading="Not quite what you're looking for?"
            description="Transform your business with our connectivity solutions tailored for SMEs and individuals. Experience the reliability, scalability, and security that set us apart."
            buttonText="Learn More"
            buttonLink="/services/internet/enterprise"
            imageSrc={require("../../../assets/images/inthero.jpg")}
            imageAlt="Office"
            imageLink="/services/internet/retail"
        />
    );
}

