import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SpeakerPhoneIcon from '@mui/icons-material/SpeakerPhone';
import LanguageIcon from '@mui/icons-material/Language';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import VpnLockIcon from '@mui/icons-material/VpnLock';

export const AboutServices = () => {
    return (
        <section className="service-section-3 fix section-padding pt-0">
            <Box className="container">
                <Box className="section-title text-center">
                    <Typography
                        variant="h6"
                        className="wow fadeInUp"
                        sx={{ fontWeight: "400", textTransform: "uppercase", color: "#E65D0F" }}
                    >
                        Empower Your Digital Transformation
                    </Typography>
                    <Typography
                        variant="h2"
                        className="wow fadeInUp"
                        data-wow-delay=".3s"
                        sx={{
                            fontSize: "36px",
                            fontWeight: "600",
                            color: "#010156",
                            lineHeight: "1.3"
                        }}
                    >
                        Comprehensive Digital Solutions for Businesses & Individuals
                    </Typography>
                    <Typography
                        variant="body1"
                        className="wow fadeInUp w-75 mx-auto"
                        data-wow-delay=".4s"
                        sx={{
                            fontSize: "18px",
                            fontWeight: "300",
                            fontFamily: "Montserrat",
                            lineHeight: "1.6"
                        }}
                    >
                        LinkOrg Networks delivers tailored solutions to power businesses and individuals.
                        From high-speed internet to enterprise VPNs, cloud storage, VoIP, and IT services,
                        our cutting-edge offerings ensure seamless connectivity and efficiency in a digital-driven world.
                    </Typography>
                </Box>

                <Box className="row">
                    {/* Internet Solutions */}
                    <Box className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s">
                        <Link to='/services/internet'>
                            <Box className="service-box-items">
                                <Box className="icon">
                                    <LanguageIcon sx={{ fontSize: 70, color: "#E65D0F" }} />
                                </Box>
                                <Box className="content">
                                    <h3>
                                        <Link to="" className="blutext">Internet Solutions</Link>
                                    </h3>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: "15px",
                                            fontWeight: "300",
                                            lineHeight: "1.5",
                                            fontFamily: "Montserrat"
                                        }}
                                    >
                                        Reliable high-speed internet for homes & businesses.
                                    </Typography>
                                    <Link to="/services/internet" className="arrow-icon">
                                        <ChevronRightIcon sx={{ fontSize: 50, color: "#010156" }} />
                                    </Link>
                                </Box>
                            </Box>
                        </Link>
                    </Box>

                    {/* Enterprise VPN */}
                    <Box className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".5s">
                        <Link to='/services/vpn-solutions'>
                            <Box className="service-box-items active">
                                <Box className="icon">
                                    <VpnLockIcon sx={{ fontSize: 70, color: "#ffffff" }} />
                                </Box>
                                <Box className="content">
                                    <h3>
                                        <Link to="/services/vpn-solutions">Enterprise VPN</Link>
                                    </h3>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: "15px",
                                            fontWeight: "300",
                                            lineHeight: "1.5",
                                            fontFamily: "Montserrat"
                                        }}
                                    >
                                        Secure & encrypted connectivity for businesses.
                                    </Typography>
                                    <Link to="/services/vpn-solutions" className="arrow-icon">
                                        <ChevronRightIcon sx={{ fontSize: 50, color: "#010156" }} />
                                    </Link>
                                </Box>
                            </Box>
                        </Link>
                    </Box>

                    {/* VoIP Solutions */}
                    <Box className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <Link to='/services/voip-services'>
                            <Box className="service-box-items">
                                <Box className="icon">
                                    <SpeakerPhoneIcon sx={{ fontSize: 70, color: "#E65D0F" }} />
                                </Box>
                                <Box className="content">
                                    <h3>
                                        <Link to="" className="blutext">VoIP Solutions</Link>
                                    </h3>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: "15px",
                                            fontWeight: "300",
                                            lineHeight: "1.5",
                                            fontFamily: "Montserrat"
                                        }}
                                    >
                                        Cost-effective, high-quality voice communication.
                                    </Typography>
                                    <Link to="/services/voip-services" className="arrow-icon">
                                        <ChevronRightIcon sx={{ fontSize: 50, color: "#010156" }} />
                                    </Link>
                                </Box>
                            </Box>
                        </Link>
                    </Box>

                    {/* IT & Cloud Solutions */}
                    <Box className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".9s">
                        <Link to='/services/it-solutions'>
                            <Box className="service-box-items">
                                <Box className="icon">
                                    <SettingsInputAntennaIcon sx={{ fontSize: 70, color: "#E65D0F" }} />
                                </Box>
                                <Box className="content">
                                    <h3>
                                        <Link to="/services/it-solutions" className="blutext">IT & Cloud Solutions</Link>
                                    </h3>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: "15px",
                                            fontWeight: "300",
                                            lineHeight: "1.5",
                                            fontFamily: "Montserrat"
                                        }}
                                    >
                                        Scalable cloud & IT infrastructure for enterprises.
                                    </Typography>
                                    <Link to="/services/it-solutions" className="arrow-icon">
                                        <ChevronRightIcon sx={{ fontSize: 50, color: "#010156" }} />
                                    </Link>
                                </Box>
                            </Box>
                        </Link>
                    </Box>
                </Box>

                <Box className="team-button text-center mx-auto mt-5 wow fadeInUp" data-wow-delay=".4s">
                    <Link to="/services" className="theme-btn theme-btn-2">
                        <span>
                            Explore Services
                            <ChevronRightIcon />
                        </span>
                    </Link>
                </Box>
            </Box>
        </section>
    );
};
