import React from "react";
import {
    Box,
    Typography,
    Button,
    Grid,
    Card,
    CardMedia,
    CardContent,
} from "@mui/material";
import { Link } from "react-router-dom";
import Cloud from "../../../../src/assets/images/subservice/virtual_servers.gif";
import Vpn from "../../../assets/images/subservice/vpn.png";
import Software from "../../../assets/images/subservice/software.jpg";
import voip from "../../../assets/images/subservice/voip.gif";
import WifiIcon from "@mui/icons-material/Wifi";
import HomeIcon from "@mui/icons-material/Home";
import ApartmentIcon from "@mui/icons-material/Apartment";
import NetworkCellIcon from "@mui/icons-material/NetworkCell";

export const OtherEnterprise = () => {
    const plans = [
        {
            title: "Storage Solutions",
            description: `Cloud storage delivers scalable, remote storage options, allowing businesses to pay for what they use while benefiting from easy access and disaster recovery capabilities. Together, these solutions cater to diverse storage needs, from high-performance to cost-effective scalability`,
            image: Cloud,
            icon: <HomeIcon fontSize="small" sx={{ color: "#010156" }} />,
            link: "/services/storage-solutions",
        },
        {
            title: "Enterprise VPN",
            description: `We help businesses connect multiple locations as if they were on the same local network, maintaining layer 2 transparency. Leased lines are dedicated, point-to-point connections between two locations, offering consistent bandwidth and performance.`,
            image: Vpn,
            icon: <ApartmentIcon fontSize="small" sx={{ color: "#010156" }} />,
            link: "/services/vpn-solutions",
        },
        {
            title: "Software Services",
            description: `We handle custom development, integration, and maintenance services that are vital for businesses looking to create tailored software solutions that meet their specific needs. Our software is built to match unique business processes, providing flexibility and functionality that off-the-shelf solutions cannot offer.`,
            image: Software,
            icon: <NetworkCellIcon fontSize="small" sx={{ color: "#010156" }} />,
            link: "/services/software-services",
        },
        {
            title: "Voice over Internet Protocol (VoIP)",
            description: `Cut down on communication costs without compromising quality. At Linkorg Network, our Voice over Internet Protocol (VoIP) solutions provide crystal-clear voice calls over the internet, ensuring you stay connected with clients and colleagues effortlessly.`,
            image: voip,
            icon: <WifiIcon fontSize="small" sx={{ color: "#010156" }} />,
            link: "/services/voip-services",
        },
    ];

    return (
        <Box sx={{ padding: { xs: 2, md: 4 }, backgroundColor: "#fff" }}>
            <Typography
                sx={{ textAlign: "center", marginBottom: 4 }}
                variant="h4"
            >
                More Enterprise Solutions
            </Typography>
            <Grid
                container
                spacing={4}
                justifyContent="center"
                sx={{
                    maxWidth: "1200px",
                    margin: "0 auto",
                }}
            >
                {plans.map((plan, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <Card
                            sx={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                boxShadow: 3,
                                borderRadius: 2,
                                overflow: "hidden",
                                background: "linear-gradient(135deg, #ffffff, #f9f9f9)",
                                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                "&:hover": {
                                    transform: "scale(1.03)",
                                    boxShadow: "0px 10px 20px rgba(0,0,0,0.2)",
                                },
                            }}
                        >
                            <Link to="">
                                <CardMedia
                                    component="img"
                                    height="180"
                                    image={plan.image}
                                    alt={plan.title}
                                    sx={{
                                        transition: "transform 0.3s ease",
                                        "&:hover": { transform: "scale(1.1)" },
                                    }}
                                />
                            </Link>
                            <CardContent
                                sx={{
                                    flexGrow: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                        marginBottom: 1,
                                    }}
                                >
                                    {plan.icon}
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{
                                            color: "#010156",
                                            fontWeight: 600,
                                            fontSize: "1.1rem",
                                        }}
                                    >
                                        {plan.title}
                                    </Typography>
                                </Box>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: "#555",
                                        whiteSpace: "pre-line",
                                        fontSize: "0.9rem",
                                    }}
                                >
                                    {plan.description}
                                </Typography>
                                <Box sx={{ marginTop: 2, textAlign: "center" }}>
                                    <Link to={plan.link}>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                background: "#010156",
                                                color: "#fff",
                                                paddingX: 3,
                                                fontSize: "0.85rem",
                                                boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                                            }}
                                        >
                                            Learn More
                                        </Button>
                                    </Link>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
