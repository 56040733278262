import React from 'react';
import Login from "../../components/Auth/Login";
import Footer from "../../components/Footer"

const LoginPage = () => {
  return (
    <>
    <Login/>
    <Footer />
    </>
  )
}

export default LoginPage