import React from 'react'
import Register from "../../components/Auth/Register";
import Footer from "../../components/Footer"

const RegisterPage = () => {
  return (
    <>
    <Register/>
    <Footer />
    </>

  )
}

export default RegisterPage